var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "권한",
                tableId: "riskHazrd01",
                columnSetting: false,
                isFullScreen: false,
                columns: _vm.grid.columns,
                data: _vm.grid.data,
              },
              on: { rowClick: _vm.rowClick },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        attrs: { label: "LBLSEARCH", icon: "search" },
                        on: { btnClicked: _vm.getList },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "LBLDETAIL" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c("c-btn", {
                            attrs: { label: "초기화", icon: "autorenew" },
                            on: { btnClicked: _vm.reset },
                          }),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: { label: "LBLSAVE", icon: "save" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            comboItems: _vm.classItems,
                            required: true,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "riskHazardClassCd",
                            label: "LBLCLASSIFICATION",
                          },
                          model: {
                            value: _vm.riskHazard.riskHazardClassCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.riskHazard, "riskHazardClassCd", $$v)
                            },
                            expression: "riskHazard.riskHazardClassCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-radio", {
                          attrs: {
                            editable: _vm.editable,
                            comboItems: _vm.useFlagItems,
                            label: "LBLUSEFLAG",
                            name: "useFlag",
                          },
                          model: {
                            value: _vm.riskHazard.useFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.riskHazard, "useFlag", $$v)
                            },
                            expression: "riskHazard.useFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            required: true,
                            label: "유해위험요인",
                            name: "riskHazardNm",
                          },
                          model: {
                            value: _vm.riskHazard.riskHazardNm,
                            callback: function ($$v) {
                              _vm.$set(_vm.riskHazard, "riskHazardNm", $$v)
                            },
                            expression: "riskHazard.riskHazardNm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "관련근거(법적기준)",
                            name: "col1",
                          },
                          model: {
                            value: _vm.riskHazard.col1,
                            callback: function ($$v) {
                              _vm.$set(_vm.riskHazard, "col1", $$v)
                            },
                            expression: "riskHazard.col1",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }